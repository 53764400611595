
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import { ContentLoader } from "vue-content-loader";
import moment from "moment";
import useEmitter from "@/composables/Emmiter";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import FullCalendar from "@fullcalendar/vue3";
import useUtilChanges from "@/composables/UtilChanges";
import { ServicoRecepcao } from "@/models/AgendaModel";

export default defineComponent({
  name: "agenda-virtual-recepcao-hyundai",
  components: {
    FullCalendar,
    ContentLoader,
    ElConfigProvider,
  },
  setup() {
    const store = useStore();
    const emitter = useEmitter();
    const { replacePlaca } = useUtilChanges();
    const loading = ref(false);
    const isUserActive = ref(false);
    const timeNow = ref(moment().format("DD/MM/YY HH:mm:ss"));
    const veiculosRecepcao = ref<ServicoRecepcao[]>([]);

    const statusOS = reactive({
      data: [] as ServicoRecepcao[],
      dataFirstPage: [] as ServicoRecepcao[],
      dataLastPage: [] as ServicoRecepcao[],
      current_page: 1,
      total: 0,
      per_page: 100, //In two tables
    });

    const refIntervals = reactive({
      baseAgendamento: 0,
    });

    // LIFECYCLE HOOKS
    onMounted(async () => {
      startPage();
      setInterval(() => {
        timeNow.value = moment().format("DD/MM/YY HH:mm:ss");
      }, 1000);

      // EMITTER HOOKS
      emitter.on("update-agenda-recepcao", () => {
        startPage();
      });

      emitter.on("agenda-clear-interval", () => {
        clearAllSetInterval();
      });

      // TODO Tranformar em socket
      inactivityTime();
    });

    onBeforeUnmount(() => {
      clearAllSetInterval();

      emitter.off("update-agenda-recepcao");
      emitter.off("agenda-clear-interval");
    });

    const dataVeiculoRecepcao = (
      veiculoRecepcao: ServicoRecepcao[] | [],
      page: number = 1
    ) => {
      statusOS.total = veiculoRecepcao.length;
      statusOS.data = [...veiculoRecepcao];

      statusOS.current_page = page;

      const auxStart = statusOS.per_page * (page - 1);
      const auxEnd = statusOS.per_page * page;
      const allData = veiculoRecepcao.slice(auxStart, auxEnd);

      statusOS.dataFirstPage = allData.slice(0, Math.round(allData.length / 2));
      statusOS.dataLastPage = allData.slice(
        Math.round(allData.length / 2),
        allData.length
      );
    };

    // WATCH HOOKS
    store.watch(
      () => store.getters.baseAgendamentosList,
      () => {
        clearAllSetInterval();
        loading.value = false;
        refIntervals.baseAgendamento = setInterval(() => {
          store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
          store.dispatch(Actions.SET_BASE_SERVICOS);
        }, 300000);

        dataVeiculoRecepcao(store.getters.baseVeiculosRecepcao);
      },
      { deep: true }
    );

    // FUNCTIONS
    function startPage() {
      window.dispatchEvent(new Event("resize"));
      inactivityTime();

      clearAllSetInterval();

      const auxUpdateTimeAgendamento =
        store.getters.refLastUpdatedTime.lastBaseAgendamentos;

      if (
        !auxUpdateTimeAgendamento ||
        moment().isAfter(auxUpdateTimeAgendamento.clone().add(210, "seconds"))
      ) {
        loading.value = true;

        store.dispatch(Actions.SET_BASE_SERVICOS);
        store.dispatch(Actions.SET_BASE_AGENDAMENTOS).then(() => {
          loading.value = false;
        });
      } else {
        dataVeiculoRecepcao(store.getters.baseVeiculosRecepcao);
      }

      refIntervals.baseAgendamento = setInterval(() => {
        store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
      }, 300000);
    }

    function chagePage(page) {
      dataVeiculoRecepcao(statusOS.data, page);
    }

    function clearAllSetInterval() {
      clearInterval(refIntervals.baseAgendamento);
    }

    function inactivityTime() {
      let time;
      // reset timer
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      function doSomething() {
        isUserActive.value = false;
      }
      function resetTimer() {
        isUserActive.value = true;
        clearTimeout(time);
        time = setTimeout(doSomething, 5000);
      }
    }

    function calendarFullscreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        return;
      }
      let elem: any = document.querySelector("#card-calendar-hyundai-recepcao");
      if (elem.requestFullscreen) {
        elem.requestFullscreen({ navigationUI: "show" });
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen({ navigationUI: "show" });
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen({ navigationUI: "show" });
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen({ navigationUI: "show" });
      }
    }

    return {
      ptBr,
      calendarFullscreen,
      loading,
      timeNow,
      isUserActive,
      headerTitle: computed(() => store.getters.concessionariaSelectedInfo.nomeFantasia),
      chagePage,
      replacePlaca,
      ...toRefs(statusOS),
      veiculosRecepcao,
    };
  },
});
